exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-templates-article-post-js": () => import("./../../../src/templates/article-post.js" /* webpackChunkName: "component---src-templates-article-post-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/CategoryPage.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-tool-page-js": () => import("./../../../src/templates/ToolPage.js" /* webpackChunkName: "component---src-templates-tool-page-js" */)
}

